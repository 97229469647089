import { createStore } from 'redux';
import { persistedReducer } from './app.reducer';
import { persistStore } from 'redux-persist';

export const store = createStore(persistedReducer);
export const persistor = persistStore(store);

export const resetStore = () => {
  persistor.purge();
  store.dispatch({ type: 'RESET_STORE' });
};
