import {
  ADD_COMPANY,
  GET_COMPANIES,
  RESET_STORE,
  SET_ENTITY,
  EDIT_COMPANY,
} from "./app.actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export interface IAppState {
  entity: object;
  companiesList: any[];
}

export const INITIAL_STATE: IAppState = {
  entity: {},
  companiesList: [],
};

class AppImpl {
  constructor(private state: IAppState, private action) {}

  fetch_entity() {
    return {
      ...this.state,
      entity: this.action.val,
    };
  }
  fetch_companies() {
    return {
      ...this.state,
      companiesList: this.action.val,
    };
  }
  resetStore() {
    return INITIAL_STATE;
  }
  add_company() {
    return {
      ...this.state,
      companiesList: [...this.state.companiesList, this.action.val],
    };
  }
  edit_company() {
    const updatedCompaniesList = this.state.companiesList.map((company) => {
      if (company.id === this.action.val.id) {
        return this.action.val;
      }
      return company;
    });

    const updatedEntity =
      (this.state.entity as any).id === this.action.val.id
        ? this.action.val
        : this.state.entity;

    return {
      ...this.state,
      companiesList: updatedCompaniesList,
      entity: updatedEntity,
    };
  }
}

export function appReducer(state = INITIAL_STATE, action): IAppState {
  const appImpl = new AppImpl(state, action);

  switch (action.type) {
    case SET_ENTITY:
      return appImpl.fetch_entity();
    case GET_COMPANIES:
      return appImpl.fetch_companies();
    case ADD_COMPANY:
      return appImpl.add_company();
    case RESET_STORE:
      return appImpl.resetStore();
    case EDIT_COMPANY:
      return appImpl.edit_company();
    default:
      return state;
  }
}

const persistConfig = {
  key: "root",
  storage,
};

export const persistedReducer = persistReducer(persistConfig, appReducer);
