import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { authResolverResolver } from "./shared/services/auth/auth-resolver.resolver";

const routes: Routes = [
  {
    path: "",
    redirectTo: "auth/login",
    pathMatch: "full",
  },
  {
    path: "auth",
    loadChildren: () => import('src/app/features/common/auth.module').then(m => m.AuthModule)
  },
  {
    path: "pages",
    loadChildren: () => import('src/app/features/features.module').then(m => m.FeatureModule)
  },
  {
    path: "**",
    redirectTo: "auth/login",
    resolve: [authResolverResolver],
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
