export function extractValuesFromJson(cloudFrontPolicy: string) {
  const tokenPolicy = cloudFrontPolicy.replace(/_+$/, "");
  const cleanJsonString = base64UrlDecode(tokenPolicy).replace(
    /[^\x20-\x7E]+/g,
    ""
  );

  try {
    if (cleanJsonString) {
      const jsonObject = JSON.parse(cleanJsonString);

      const resource = extractBaseUrl(jsonObject.Statement[0].Resource);
      const epochTime =
        jsonObject.Statement[0].Condition.DateLessThan["AWS:EpochTime"];
      return { resource, epochTime }; // Return both resource and epochTime
    }
  } catch (error) {
    console.error("Failed to parse JSON:", error);
    return null;
  }
}
function base64UrlDecode(arg) {
  arg = arg.replace(/-/g, "+").replace(/_/g, "/");
  switch (arg.length % 4) {
    case 2:
      arg += "==";
      break;
    case 3:
      arg += "=";
      break;
  }
  return window.atob(arg);
}
function extractBaseUrl(url) {
  const pattern = /^(https?:\/\/.*\/).*\*$/;
  const match = url.match(pattern);
  return match ? match[1] : null;
}
export function extractSegment(url) {
  const pattern = /https:\/\/invoices\.m1dns\.com\/([^\/]*)\/\*/;
  const match = url.match(pattern);

  return match ? match[1] : null;
}
